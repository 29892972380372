import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    });
  }

  async end({ item, oldIndex, newIndex }) {
    const id   = item.dataset.id;
    const data = new FormData();

      data.append('position', newIndex + 1);

    const response = await fetch(this.data.get('url').replace(':id', id), {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: data
    });

    await response.json();
  }
}