import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'nav'];

  connect() { }

  toggle() {
    this.navTarget.classList.toggle('hidden');
  }

  disconnect() { }
}