import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'background',
    'foreground',
    'slider',
    'handle'
  ];
  
  connect() { }

  preview(event) {
    const position = event.currentTarget.value;
  
    this.foregroundTarget.style.width = `${ position }%`;
    this.handleTarget.style.left = `calc(${ position }% - 22px)`;
  }
}