import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

import Clipboard from 'stimulus-clipboard';

const application = Application.start();

application.register('clipboard', Clipboard);

registerControllers(application, import.meta.globEager('./**/*_controller.(js|ts|jsx|tsx)'));

application.warnings = true;
application.debug    = true;

window.Stimulus      = application;