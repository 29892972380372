import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() { }

  debounce(event) {
    clearTimeout(this.timeout);

    const value = event.currentTarget.value;
    const length = value.length;

    if (length === 0 || length > 2) {
      this.timeout = setTimeout(() => { this.submit() }, 2500);
    }
  }

  filter() { this.submit() }
  submit() { this.form.requestSubmit(); }

  get form() {
    return (this.hasFormTarget ? this.formTarget : this.element);
  }


  disconnect() { }
}