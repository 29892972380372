import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'output'];

  submit(event) {
    event.preventDefault();

    const value = this.inputTarget.value;
    const id = value.match(/[-\w]{25,}/);

    if (id) {
      const widget = document.getElementById('widget').checked;
      const widgetQuery = widget ? 'true' : 'false';
      const headers = document.getElementById('headers').checked;
      const headersQuery = headers ? 'true' : 'false';

      const output = `<iframe src="https://docs.google.com/spreadsheets/d/${id[0]}/pubhtml?widget=${widgetQuery}&amp;headers=${headersQuery}"></iframe>`;
      navigator.clipboard.writeText(output);

      document.getElementById("copiedToClipboardDiv").classList.remove('hidden')

            // Create an anchor element with the output URL as the href
            const linkElement = document.createElement('a');
            linkElement.href = output;
            linkElement.textContent = 'Copied to clipboard: ' + output;
      
            // Replace the innerHTML of the outputTarget with the anchor element
            this.outputTarget.innerHTML = '';
            this.outputTarget.appendChild(linkElement);
      
     // this.outputTarget.innerHTML = `Copied to clipboard: ${ output }`;
    }
  }
}