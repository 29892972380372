import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'output'];

  async submit(event) {
    event.preventDefault();
    const value = this.inputTarget.value;

    // Get the regex value from the input field
    
    const usecase = document.querySelector('input[name="usecase"]').value
    const texttoreplace =document.querySelector('input[name="texttoreplace"]').value;
    const cellValue = document.querySelector('input[name="cell"]').value;
    if (!cellValue || cellValue == "" | cellValue == null || cellValue == " " ){
      const cellValue = "A1"
    }
    const prompt = "You are helping to figure out the regular expression needed to replace a string of text. Specifically need to generate a regereplace() formula for the user. Only include the regular expression and replacement, do not explain your response. " 
    + String.raw `Here are examples: 
    Use Case: Replace word with replacement.
    Text to Replace: the word word in this sentance.
    Range: A1
    Response: =REGEXREPLACE(A1,"word","replacement")
    Use Case: Remove extra spaces.
    Text to Replace:multiple  spaces with a  single space in cell A1.
    Response: =REGEXREPLACE(A1, "\s+", " ")
    Use Case: Remove HTML Tags.
    Text to Replace: <p>Some text</p> <ul> <li>Item 1</li> <li>Item 2</li> </ul>
    Range: A1.
    Response: =REGEXREPLACE(A1, "<.*?>", "")
    Use Case: Replace Line Breaks with a comma.
    Text to Replace: Line 1 \n Line 2 \n Line 3
    Response: =REGEXREPLACE(A1, "\n", ", ")
    Use Case: `
    // Make a request to the OpenAI API
    
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ENV['OPENAI_REGEXREPLACER'] // Replace YOUR_OPENAI_API_KEY with your actual API key
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{"role": "user", "content": prompt + usecase + " Text to replace: " + texttoreplace + "Range" + cellValue + "Response: "}],
        max_tokens: 500 // You can adjust the max_tokens parameter based on your requirement
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const output = data.choices[0].message.content;
  

      // Copy the output to clipboard
      navigator.clipboard.writeText(output);

      // Show copied message
      document.getElementById("copiedToClipboardDiv").classList.remove('hidden');

      // Display the output
      this.outputTarget.textContent = 'Copied to clipboard: ' + output;
    } else {
      console.error('Failed to fetch data from OpenAI API');
    }
  }
}