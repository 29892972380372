import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'output'];

  submit(event) {
    event.preventDefault();

    const value = this.inputTarget.value;
    const id = value.match(/[-\w]{25,}/);

    if (id) {
      const output = 'https://docs.google.com/spreadsheets/d/' + id[0] + '/copy';
      navigator.clipboard.writeText(output);

      document.getElementById("copiedToClipboardDiv").classList.remove('hidden')
      this.outputTarget.innerHTML = `Copied to clipboard: ${ output }`;
    }
  }
}