import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'output'];

  async submit(event) {
    event.preventDefault();
    const value = this.inputTarget.value;

    // Get the regex value from the input field
    
    const usecase = document.querySelector('input[name="usecase"]').value
    const texttomatch =document.querySelector('input[name="texttomatch"]').value;
    const cellValue = document.querySelector('input[name="cell"]').value;
    if (!cellValue || cellValue == "" | cellValue == null || cellValue == " " ){
      const cellValue = "A1"
    }
    const prompt = "You are helping to figure out the regular expression needed to match a string of text. Only include the regular expression, not the words response in your response. " + String.raw `Here are two examples: Use Case: Check if a cell contains a valid email address. Text to Match: john.doe@example.com Response: [\w\-]+(\.[\w\-]+)*@[\w\-]+(\.[\w\-]+)*(\.\w{2,3})+ Prompt: Verify if a cell contains a valid phone number. Text to Match: 987-123-4567 Response: \d{3}-\d{3}-\d{4} This is the use case: `
    // Make a request to the OpenAI API
    
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ENV['OPENAI_REGEXMATCHER'] // Replace YOUR_OPENAI_API_KEY with your actual API key
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{"role": "user", "content": prompt + usecase + " Text to match: " + texttomatch}],
        max_tokens: 500 // You can adjust the max_tokens parameter based on your requirement
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const regexpression = data.choices[0].message.content;
      const output =  '=REGEXMATCH(' + cellValue + ',"'+ regexpression +'")' // Get the generated text from the OpenAI API response

      // Copy the output to clipboard
      navigator.clipboard.writeText(output);

      // Show copied message
      document.getElementById("copiedToClipboardDiv").classList.remove('hidden');

      // Display the output
      this.outputTarget.textContent = 'Copied to clipboard: ' + output;
    } else {
      console.error('Failed to fetch data from OpenAI API');
    }
  }
}