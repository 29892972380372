import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['input', 'output'];

  async submit(event) {
    event.preventDefault();
    const value = this.inputTarget.value;

    // Get the regex value from the input field
    
    const usecase = document.querySelector('textarea[name="usecase"]').value
    const cellValue = document.querySelector('input[name="cell"]').value;
    if (!cellValue || cellValue == "" | cellValue == null || cellValue == " " ){
      const cellValue = "A1"
    }
    const userRole = `You are a helpful conditional formatting generator. You will help generate a Google Sheets conditional formatting to use in a google sheet. `
    const prompt = "You will generate the conditional formatting to use in a google sheet. Use any existing Google Sheets conditional formatting. Do not include the explanation in your response. Reply with only the conditional formatting including the equals (=) sign that starts every formula if using a custom formula in Conditional formatting. " 
      + String.raw `
      Use Case: `
    // Make a request to the OpenAI API
    
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ENV['OPENAI_FORMATTER'] // Replace YOUR_OPENAI_API_KEY with your actual API key
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{"role": "system", "content": userRole}
        ,{"role": "user", "content": prompt + usecase + `
        Range to use: ` + cellValue}],
        max_tokens: 500 // You can adjust the max_tokens parameter based on your requirement
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const formatting = data.choices[0].message.content;
      const output =  formatting // Get the generated text from the OpenAI API response

      // Copy the output to clipboard
      navigator.clipboard.writeText(output);

      //add cookie to track number of times used
      var count = Cookies.get('count'); 
      if (count == null) {
        Cookies.set('count', 1);
      } else {
        Cookies.set('count', parseInt(count) + 1);
      }
      //check if user is logged in user. check rails controller for current user in rails controller
      var current_user = Cookies.get('current_user');

      if ( !current_user  ) {
        // show modal to sign in or register to become a member
        document.getElementById("modal").classList.remove('hidden');
        // disable button id:getFormatting
        document.getElementById("getFormatting").disabled = true;
        // add tailwind class diabled to getFormula id
        document.getElementById("getFormatting").classList.add('disabled:opacity-50');

      }

      // Show copied message
      document.getElementById("copiedToClipboardDiv").classList.remove('hidden');

      // Display the output
      this.outputTarget.textContent = 'Copied to clipboard: ' + output;
    } else {
      console.error('Failed to fetch data from OpenAI API');
    }
  }
}