import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'output'];

  async submit(event) {
    event.preventDefault();
    const value = this.inputTarget.value;

    // Get the regex value from the input field
    
    const usecase = document.querySelector('input[name="usecase"]').value
    const cellValue = document.querySelector('input[name="cell"]').value;
    if (!cellValue || cellValue == "" | cellValue == null || cellValue == " " ){
      const cellValue = "A1"
    }
    const userRole = `You are a helpful sparkline formula generator. You will help generate a sparkline formula to use in a google sheet.
      Syntax is =SPARKLINE(data, [options])
      data - The range or array containing the data to plot.

      options - [ OPTIONAL ] - A range or array of optional settings and associated values used to customize the chart.

      If referencing a range, options should be two cells wide where the first cell is the option and the second cell is the value that option is set to.

      The "charttype" option defines the type of chart to plot, which includes:

      "line" for a line graph (the default)
      "bar" for a stacked bar chart
      "column" for a column chart
      "winloss" for a special type of column chart that plots 2 possible outcomes: positive and negative (like a coin toss, heads or tails).
      For line graphs:

      "xmin" sets the minimum value along the horizontal axis.

      "xmax" sets the maximum value along the horizontal axis.

      "ymin" sets the minimum value along the vertical axis.

      "ymax" sets the maximum value along the vertical axis.

      "color" sets the color of the line.
      "empty" sets how to treat empty cells. Possible corresponding values include: "zero" or "ignore".
      "nan" sets how to treat cells with non-numeric data. Options are: "convert" and "ignore".
      "rtl" determines whether or not the chart is rendered right to left. Options are true or false.
      "linewidth" determines how thick the line will be in the chart. A higher number means a thicker line.
      For column and winloss sparklines:

      "color" sets the color of chart columns.
      "lowcolor" sets the color for the lowest value in the chart
      "highcolor" sets the color for the higest value in the chart
      "firstcolor" sets the color of the first column
      "lastcolor" sets the color of the last column
      "negcolor" sets the color of all negative columns
      "empty" sets how to treat empty cells. Possible corresponding values include: "zero" or "ignore".
      "nan" sets how to treat cells with non-numeric data. Options are: "convert" and "ignore".
      "axis" decides if an axis needs to be drawn (true/false)
      "axiscolor" sets the color of the axis (if applicable)
      "ymin" sets the custom minimum data value that should be used for scaling the height of columns (not applicable for win/loss)
      "ymax" sets the custom maximum data value that should be used for scaling the height of columns (not applicable for win/loss)
      "rtl" determines whether or not the chart is rendered right to left. Options are true or false.
      For bar charts:

      "max" sets the maximum value along the horizontal axis.
      "color1" sets the first color used for bars in the chart.
      "color2" sets the second color used for bars in the chart.
      "empty" sets how to treat empty cells. Possible corresponding values include: "zero" or "ignore".
      "nan" sets how to treat cells with non-numeric data. Options are: "convert" and "ignore".
      "rtl" determines whether or not the chart is rendered right to left. Options are true or false.
      Notes
      Colors can be written using their names (e.g., "green") or as a hex code (e.g., "#3D3D3D").
      `
    const prompt = "You will generate a sparkline formula to use in a google sheet. Only include the sparkline formula. Do not include the explanation in your response. Reply with only the sparkline() formula including =SPARKLINE() an then insert the formula and range appropriately. " 
      + String.raw `
      Here are some examples: 
      Use Case: I need to create a red column chart. 
      Range to use: A1:A5
      Response: SPARKLINE(A1:A5, {"charttype","column"; "axis", true; "axiscolor", "red"})
      Use case: I want to make a bar chart with maximum 40
      Range to use: A2:E2 
      Response: SPARKLINE(A2:E2,{"charttype","bar";"max",40})
      Use Case: `
    // Make a request to the OpenAI API
    
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ENV['OPENAI_SPARKLINER'] // Replace YOUR_OPENAI_API_KEY with your actual API key
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{"role": "system", "content": userRole}
        ,{"role": "user", "content": prompt + usecase + `
        Range to use: ` + cellValue}],
        max_tokens: 500 // You can adjust the max_tokens parameter based on your requirement
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const sparkline_formula = data.choices[0].message.content;
      const output =  sparkline_formula // Get the generated text from the OpenAI API response

      // Copy the output to clipboard
      navigator.clipboard.writeText(output);

      // Show copied message
      document.getElementById("copiedToClipboardDiv").classList.remove('hidden');

      // Display the output
      this.outputTarget.textContent = 'Copied to clipboard: ' + output;
    } else {
      console.error('Failed to fetch data from OpenAI API');
    }
  }
}