import '../controllers';

import * as Turbo from '@hotwired/turbo';
import * as ActiveStorage from '@rails/activestorage';

import * as Trix from 'trix';
import '@rails/actiontext';
import 'trix/dist/trix.css';

ActiveStorage.start();
Turbo.start();

import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';

document.addEventListener('turbo:load', (event) => {
  const selects = document.getElementsByClassName('form-select');

  for (let select of selects) {
    new Choices(select, {
      searchEnabled: true
    });
  }
});

Trix.config.blockAttributes.heading1 = { tagName: 'h2' };