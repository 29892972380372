import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'output'];

  submit(event) {
    event.preventDefault();

    const value = this.inputTarget.value;
    const id = value.match(/[-\w]{25,}/);

    if (id) {
      //const output = 'https://docs.google.com/spreadsheets/d/' + id[0] + '/export?format=pdf';
      const orientation = document.querySelector('input[name="orientation"]:checked').value;
      const portraitQuery = orientation === 'portrait' ? 'true' : 'false';
      const gridlines = document.getElementById('gridlines').checked;
      const gridlinesQuery = gridlines ? 'true' : 'false';
      const printtitle = document.getElementById('printtitle').checked;
      const printtitleQuery = printtitle ? 'true' : 'false';
      const pagenum = document.getElementById('pagenum').checked;
      const pagenumQuery = pagenum ? 'CENTER' : 'UNDEFINED';
      const fitw = document.getElementById('fitw').checked;
      const fitwQuery = fitw ? 'true' : 'false';
      const fzr = document.getElementById('fzr').checked;
      const fzrQuery = fzr ? 'true' : 'false';
      const sheetnames = document.getElementById('sheetnames').checked;
      const sheetnamesQuery = sheetnames ? 'true' : 'false';
      const size = document.getElementById('size').value;
      const output = `https://docs.google.com/spreadsheets/d/${id[0]}/export?format=pdf&portrait=${portraitQuery}&gridlines=${gridlinesQuery}&printtitle=${printtitleQuery}&pagenum=${pagenumQuery}&fitw=${fitwQuery}&fzr=${fzrQuery}&sheetnames=${sheetnamesQuery}&size=${size}`;
      navigator.clipboard.writeText(output);

      document.getElementById("copiedToClipboardDiv").classList.remove('hidden')

            // Create an anchor element with the output URL as the href
            const linkElement = document.createElement('a');
            linkElement.href = output;
            linkElement.textContent = 'Copied to clipboard: ' + output;
      
            // Replace the innerHTML of the outputTarget with the anchor element
            this.outputTarget.innerHTML = '';
            this.outputTarget.appendChild(linkElement);
      
     // this.outputTarget.innerHTML = `Copied to clipboard: ${ output }`;
    }
  }
}