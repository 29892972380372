import { Controller } from '@hotwired/stimulus';

import * as React from 'react';
import * as ReactDOM from "react-dom/client";

import Editor from '@monaco-editor/react';

export default class extends Controller {
  static targets = ['input', 'output'];
  static values = {
    code: String,
    readOnly: Boolean
  }

  options = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: this.readOnlyValue,
    cursorStyle: 'line',
    automaticLayout: true,
    scrollbar: {
      useShadows: false,
      verticalHasArrows: true,
      horizontalHasArrows: true,
      vertical: 'auto',
      horizontal: 'auto',
      verticalScrollbarSize: 12,
      horizontalScrollbarSize: 12,
      arrowSize: 0
    },
    minimap: {
      enabled: false
    }
  }

  initialize() { }

  connect() {
    this.root = ReactDOM.createRoot(this.inputTarget);
    this.root.render(
      <Editor
        height='398px'
        defaultLanguage='javascript'
        defaultValue={ this.codeValue }
        onMount={ (editor, monaco) => this.onEditorDidMount(editor, monaco) }
        onChange={ (value, event) => this.onCodeChange(event, value) }
        options={ this.options }
      />
    );
  }

  disconnect() {
    this.root.dispose();
  }

  onEditorDidMount(editor, monaco) {
    monaco.editor.defineTheme('vs-bettersheets', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#f8f9fa'
      }
    });

    monaco.editor.setTheme('vs-bettersheets');
    editor.focus();
  }

  onCodeChange(event, value) {
    this.outputTarget.value = value;
  }
}