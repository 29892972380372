import { Controller } from '@hotwired/stimulus';

import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/github.css';

import javascript from 'highlight.js/lib/languages/javascript';

hljs.registerLanguage('javascript', javascript);

export default class extends Controller {
  static targets = ['input', 'output'];

  connect() {
    // const html = hljs.highlightAuto(this.codeTarget.querySelector('pre').innerHTML).value
    // this.codeTarget.querySelector('pre').innerHTML = html

    hljs.highlightElement(this.element.querySelector('code'));
  }
}