import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checker', 'subscribeTarget', 'unsubscribeTarget' ];
  
  connect() { }

  toggleSubscribeAll(event) {
    event.preventDefault();

    this.checkerTargets.forEach(target => {
      if (target == this.subscribeTarget) return;
      
      // Simulate the HTTP request since we know that this action will trigger a Hotwire request to the server.
      // 
      target.checked = true;
      target.form.dispatchEvent(new Event('change'));
    });
  }

  toggleUnsubscribeAll(event) {
    event.preventDefault();
    
    this.checkerTargets.forEach(target => {
      if (!target.checked) return;
      
      // Simulate the HTTP request since we know that this action will trigger a Hotwire request to the server.
      // 
      target.checked = false;
      target.form.dispatchEvent(new Event('change'));
    });
  }
}
