import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['input', 'output'];

  async submit(event) {
    event.preventDefault();
    const value = this.inputTarget.value;
    const currentUserId = document.body.dataset.userId;
    const currentUserMembership = document.body.dataset.userMembership;
    //check if user is logged in user. check rails controller for current user in rails controller
    var current_user = Cookies.get('current_user');
    
    if ( current_user || currentUserMembership == "lifetime" || currentUserMembership == "monthly" || currentUserMembership == "yearly"){
      // hide vsothers 
      document.getElementById("vsothers").classList.add('hidden');
      // Get the regex value from the input field
      const usecase = document.querySelector('textarea[name="usecase"]').value
      const cellValue = document.querySelector('input[name="cell"]').value;
      if (!cellValue || cellValue == "" | cellValue == null || cellValue == " " ){
        const cellValue = "A1"
      }
      const userRole = `You are a helpful formula generator. You will help generate a Google Sheets formula to use in a google sheet. `
      const prompt = "You will generate a formula to use in a google sheet. Use any existing Google Sheets formula. Do not include the explanation in your response. Reply with only the formula including the equals (=) sign that starts every formula. Then insert the formula and range appropriately. " 
        + String.raw `
        Here are some examples: 
        Use Case: I need to create a red column chart. 
        Range to use: A1:A5
        Response: SPARKLINE(A1:A5, {"charttype","column"; "axis", true; "axiscolor", "red"})
        Use case: I want to make a bar chart with maximum 40
        Range to use: A2:E2 
        Response: SPARKLINE(A2:E2,{"charttype","bar";"max",40})
        Use Case: `
      // Make a request to the OpenAI API

      const response = await fetch('openaiapi', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userRole: userRole,
          prompt: prompt + usecase + `
          Range to use: ` + cellValue
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const formula = data.choices[0].message.content;
        const output =  formula // Get the generated text from the OpenAI API response

        // Copy the output to clipboard
        navigator.clipboard.writeText(output);

        //add cookie to track number of times used
        var count = Cookies.get('count'); 
        if (count == null) {
          Cookies.set('count', 1);
        } else {
          Cookies.set('count', parseInt(count) + 1);
        }

        // Show copied message
        document.getElementById("copiedToClipboardDiv").classList.remove('hidden');

        // Display the output
        this.outputTarget.textContent = 'Copied to clipboard: ' + output;
      } else {
        console.error('Failed to fetch data from OpenAI API');
      }


    }
    else {
      // show modal to sign in or register to become a member and vs.
      document.getElementById("modal").classList.remove('hidden');
      // disable button id:getFormula
      document.getElementById("getFormula").disabled = true;
      // add tailwind class diabled to getFormula id
      document.getElementById("getFormula").classList.add('disabled:opacity-50');
      
    }
    
  }
}